<div *ngIf="posts" class="ng-border">
  <mat-card class="ng-card mat-elevation-z0 ng-card-outline" *ngFor="let post of posts">
    <ng-container *ngIf="post.id">
      <img *ngIf="post.image" mat-card-image src="{{ post.image }}" alt="{{ post.title }}"
        [routerLink]="['/post', post.id, post.slug]" class="ng-link" />
      <h1 [routerLink]="['/post', post.id, post.slug]" class="ng-post-title">
        <mat-icon color="primary" class="ng-icon-text">library_books</mat-icon>{{ post.title }}
      </h1>
      <h6><i>{{ post.minutes }} min read</i></h6>
      <div class="ng-author ng-link" [routerLink]="['/u', post.authorId, post.authorDoc?.username]">
        <div mat-icon-button>
          <img *ngIf="post.authorDoc?.photoURL" class="avatar" src="{{ post.authorDoc?.photoURL }}" />
          <mat-icon *ngIf="!post.authorDoc?.photoURL" class="ng-photo-icon">account_circle</mat-icon>
        </div>
        <p><small><b>{{ post.authorDoc?.username }}</b>
            <ng-container *ngIf="post.createdAt"> on
              {{ post.createdAt | date: 'EEEE, MMMM d, yyyy' }}</ng-container>
            <ng-container *ngIf="post.updatedAt"> (last modified on
              {{ post.updatedAt | date: 'EEEE, MMMM d, yyyy' }})</ng-container>
          </small></p>
      </div>
      <br />
      <div class="mat-chip-area">
        <mat-chip-list aria-label="Tags" *ngFor="let tag of post.tags" class="tags">
          <mat-chip [routerLink]="['/t', tag]">{{ tag }}</mat-chip>
        </mat-chip-list>
      </div>
      <br />
      <mat-divider inset></mat-divider>
      <mat-card-actions>
        <div class="flex-container">
          <ng-container *ngIf="this.user$ | async as user; else elseBlock">
            <div>
              <app-save [postId]="post.id" [userId]="user.uid">
              </app-save>
              <app-heart [count]="post.heartsCount" [postId]="post.id" [userId]="user.uid">
              </app-heart>
              <span [routerLink]="['/post', post.id, post.slug]" class="cursor">
                <mat-icon color="primary" class="ng-icon-text">library_books</mat-icon>Read More
              </span>
            </div>
            <ng-container
              *ngIf="post.authorId === user.uid ">
              <button mat-icon-button [routerLink]="['/edit', post.id]">
                <mat-icon>edit</mat-icon>
              </button>
            </ng-container>
          </ng-container>
          <ng-template #elseBlock>
            <div>
              <app-save [postId]="post.id">
              </app-save>
              <app-heart [count]="post.heartsCount" [postId]="post.id">
              </app-heart>
              <!-- <mat-icon  [routerLink]="['/post', post.id, post.slug]" color="primary" class="ng-icon-text">library_books</mat-icon> -->
              <span [routerLink]="['/post', post.id, post.slug]" class="cursor">
                <mat-icon color="primary" class="ng-icon-text">library_books</mat-icon>Read More
              </span>
            </div>
          </ng-template>
        </div>
      </mat-card-actions>
    </ng-container>
  </mat-card>
</div>
<ng-container *ngIf="total as t">
  <ng-container *ngIf="t ==='none'">
    <mat-card class="ng-card">There are no posts here yet!</mat-card>
  </ng-container>
  <mat-paginator class="ng-transparent" [length]="t" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
    aria-label="Select page" (page)="pageChange($event)">
  </mat-paginator>
</ng-container>