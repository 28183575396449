<!-- <h3 class="ng-center"><b>Total Posts: {{ total }}</b></h3> -->
<app-tag-list></app-tag-list>
<br />
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6532644608556126"
  crossorigin="anonymous"></script>
<!-- leftbar -->
<ins class="adsbygoogle" style="display:block" data-ad-client="ca-pub-6532644608556126" data-ad-slot="7587357156"
  data-ad-format="auto" data-full-width-responsive="true" attr.data-adtest="{{ isDev ? 'on' : 'off' }}"></ins>
<script>
  (adsbygoogle = window.adsbygoogle || []).push({});
</script>
