<mat-card class="ng-card {{ this.dm.isDarkMode ? '' : 'ng-blue-back' }}">
  <div class="ng-center">
    <h1>
      <mat-icon color="primary"
        class="ng-icon-text {{ this.dm.isDarkMode ? this.env.icon_dark_class : this.env.icon_class }}">
        {{ env.icon }}
      </mat-icon>
      {{ env.title }}
    </h1>
    <h2>{{ env.short_desc }}</h2>
  </div>
</mat-card>
<mat-tab-group class="ng-card" animationDuration="0ms" (selectedIndexChange)="tabChange($event)">
  <mat-tab label="New">
    <ng-template matTabContent>
      <br>
      <app-post-list></app-post-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="Updated">
    <ng-template matTabContent>
      <br>
      <app-post-list></app-post-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="Top">
    <ng-template matTabContent>
      <br>
      <app-post-list></app-post-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="Share Your Journey">
    <ng-template matTabContent>
      <br>
      <button (click)="newPost()" class="button">Share Your Journey</button>

    </ng-template>
  </mat-tab>
</mat-tab-group>
