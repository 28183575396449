import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { MarkdownModule } from 'ngx-markdown';
import { CommentsModule } from './comments/comments.module';
import { PostComponent } from './post.component';


@NgModule({
  declarations: [
    PostComponent
  ],
  imports: [
    SharedModule,
    CommentsModule,
    MarkdownModule.forChild()
  ],
  exports: [
    PostComponent,
    CommentsModule,
  ]
})
export class PostModule { }
