<app-header></app-header>
<div [class]="this.ns.simple ? 'simple-wrapper' : 'wrapper'">
  <nav class="header">
    <app-subheader></app-subheader>
  </nav>
  <article class="content">
    <router-outlet></router-outlet>
  </article>
  <nav class="leftnav">
    <app-leftnav></app-leftnav>
  </nav>
  <footer class="footer">
    <app-footer></app-footer>
  </footer>
</div>
