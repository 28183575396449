export const environment = {
  production: true,
  firebase:  {
    projectId: 'ceup-5e97f',
    appId: '1:445918182682:web:4d47127e6b4912a98d80a3',
    storageBucket: 'ceup-5e97f.appspot.com',
    apiKey: 'AIzaSyBJjyNFiqFmEuw2O91PRvTP7Z0QSAq0TcM',
    authDomain: 'ceup-5e97f.firebaseapp.com',
    messagingSenderId: '445918182682',
    measurementId: 'G-S47MVTP91K',
  },
  title: 'Spinal Injury Recovery',
  domain: 'Spinal Injury Recovery',
  description: 'A community of spinal injury survivors and their families.',
  short_desc: 'A community of spinal injury survivors and their families.',
  site: "https://Spinerecovery.co",
  storage: 'sir',
  author: 'Nitin',
  icon: 'health_and_safety',
  icon_class: 'ng-fire',
  icon_dark_class: 'ng-fire',
  back: ''
};

/*export const environment = {
  production: false,
  supabase_url: process.env["SUPABASE_URL"] as string,
  supabase_key: process.env["SUPABASE_KEY"] as string,
  title: 'Code.Build',
  domain: 'code.build',
  description: 'A blog about Databases, Searching, Indexing, Programming, Security, Hosting, and Other Website Technologies!',
  short_desc: 'An easier way to code',
  site: "https://code.build",
  storage: 'code-build',
  author: 'Jonathan Gamble'
};*/

