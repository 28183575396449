import { Component, Input, OnInit } from '@angular/core';
import { UserDbService } from '@db/user/user-db.service';
import { CommentDbService } from '@post/comments/services/comment-db.service';
import { from, switchMap } from 'rxjs';
import { CommentsService } from '../../services/comments.service';
import { ActiveCommentInterface } from '../../types/activeComment.interface';
import { CommentInterface } from '../../types/comment.interface';

@Component({
  selector: 'comments',
  templateUrl: './comments.component.html',
})
export class CommentsComponent implements OnInit {
  @Input() currentUserId!: string;
  @Input() postId!: string;
  @Input() userName!: string;
  comments: CommentInterface[] = [];
  activeComment: ActiveCommentInterface | null = null;

  constructor(private cms: CommentDbService,
    private us: UserDbService) {}

  ngOnInit(): void {
    this.cms.getCommentsByPost(this.postId).subscribe((comments) => {

      this.comments = comments.comments;
    });
  }

  getRootComments(): CommentInterface[] {
    return this.comments.filter((comment) => comment.parentId === null);
  }

  updateComment({
    text,
    commentId,
  }: {
    text: string;
    commentId: string;
  }): void {
    // this.commentsService
    //   .updateComment(commentId, text)
    //   .subscribe((updatedComment) => {
    //     this.comments = this.comments.map((comment) => {
    //       if (comment.id === commentId) {
    //         return updatedComment;
    //       }
    //       return comment;
    //     });

    //     this.activeComment = null;
    //   });

      const updatedFields = { body: text };

      this.cms
        .updateComment(this.postId, commentId, updatedFields)
        .then(() => {
          // console.log('Comment updated');
          this.getAllComments(); // Refresh comments list
          this.activeComment = null;
        })
        .catch((error) => {
          console.error('Error updating comment:', error);
        });
  }

  deleteComment(commentId: string): void {
    // this.cms.deleteComment(this.postId,commentId);
    this.cms.deleteComment(this.postId,commentId).then(() => {
      // console.log('Comment deleted');
      this.getAllComments();
    });
    // .subscribe(() => {
    //   this.comments = this.comments.filter(
    //     (comment) => comment.id !== commentId
    //   );
    // });
  }

  setActiveComment(activeComment: ActiveCommentInterface | null): void {
    // console.log('activeComment', activeComment);
    // console.log('activeComment', activeComment?.id);
    this.activeComment = activeComment;
  }

  async addComment({
    text,
    parentId,
  }: {
    text: string;
    parentId: string | null;
  }): Promise<void> {
    // console.log(' ----------------- '+this.currentUserId, this.userName);
    // console.log('parentId', parentId);
    // this.commentsService
    //   .createComment(text, parentId,this.currentUserId, 'this.userName')
    //   .subscribe((createdComment) => {
    //     this.comments = [...this.comments, createdComment];
    //     this.activeComment = null;
    //   });
    let id= crypto.randomUUID();
    let { data: user, error: e } = await this.us.getUser();
    if (e) {
      console.error(e);
    }
    let uid = user?.uid;
    let username = user?.username;

    const newComment: CommentInterface = {
      id: id,
      body: text,
      username: username,
      userId: uid,
      parentId: parentId,
      createdAt: new Date().toISOString(),
      postId: this.postId,
    };
      // const newComment: CommentInterface = {
      //   id: crypto.randomUUID(),
      //   body: text,
      //   username: this.userName,
      //   userId: this.currentUserId,
      //   parentId: null,
      //   createdAt: new Date().toISOString(),
      //   postId: this.postId,
      // };
  // console.log('this.postIdthis.postIdthis.postIdthis.postId ', this.postId );
        // body: text,
        this.cms.addComment(this.postId,newComment).then(() => {
    // console.log('Comment added successfully');
    this.getAllComments();
  }).catch((error) => {
    console.error('Error adding comment:', error);
  });

  }
  getAllComments() {
    this.cms.getCommentsByPost(this.postId).subscribe((data) => {
      this.comments = data?.comments || [];
      // console.log('Comments:', this.comments);
    });
  }

  getReplies(commentId: string): CommentInterface[] {
    // console.log('ROOTCOMMENT<ID>', commentId);
    // console.log('ROCONNEBTSSSSSSSS', this.comments);
    return this.comments
      .filter((comment) => comment.parentId === commentId)
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  }

   
}
