import { Injectable } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { collection, collectionData, deleteDoc, doc, docData, Firestore, getDoc, query, setDoc, snapToData, Timestamp, updateDoc, where } from '@angular/fire/firestore';
import { UserRec } from '@auth/user.model';
import { UserDbService } from '@db/user/user-db.service';
import { Post } from '@post/post.model';

import { user } from 'rxfire/auth';
import { firstValueFrom, from, Observable, switchMap } from 'rxjs';
import { CommentDocument, CommentInterface } from '../types/comment.interface';


interface UserCredentials {
  uid: string;
  username: string;
}


@Injectable({
  providedIn: 'root'
})
export class CommentDbService {

  private commentsCollection = collection(this.firestore, 'comments');
  constructor(private firestore: Firestore, private us: UserDbService, private auth: Auth) { }

  async getCurrentUserUid() {
    let { data: user, error: e } = await this.us.getUser();
    if (e) {
      console.error(e);
    }
    let uid = user?.uid;
    return uid;
  }
    
 
  // Add a new comment to an existing post document
  async addComment(postId: string,newComment : CommentInterface): Promise<void> {
    // let { data: user, error: e } = await this.us.getUser();
    // if (e) {
    //   console.error(e);
    // }
    // let uid = user?.uid;
    // let username = user?.username;

    // const newComment: CommentInterface = {
    //   id: id,
    //   body: text,
    //   username: username,
    //   userId: uid,
    //   parentId: null,
    //   createdAt: new Date().toISOString(),
    //   postId: postId,
    // };
    const postDocRef = doc(this.firestore, `comments/${postId}`);
    


    // console.log('postDocRef', postDocRef);
    // const newDocument: CommentDocument = {
    //   id: postId,
    //   postId: postId,
    //   comments: [newComment],
    // };
    // await setDoc(postDocRef, newDocument);
  // Fetch the document to see if it exists
  const postSnapshot = await firstValueFrom(docData(postDocRef));
  // console.log('postSnapshot', postSnapshot);
  if (postSnapshot) {
    // If the document exists, append the new comment to the existing comments array
    const postData = postSnapshot as CommentDocument;
    const updatedComments = [...postData.comments, newComment];

    await updateDoc(postDocRef, { comments: updatedComments });
  } else {
    // If the document doesn't exist, create it with the new comment in an array
    const newDocument: CommentDocument = {
      id: postId,
      postId: postId,
      comments: [newComment],
    };

    await setDoc(postDocRef, newDocument);
  }
  }

  async getUserCredentials(): Promise<UserCredentials> {
    return new Promise((resolve) => {
      user(this.auth).subscribe((user) => {
        // console.log('user', user);
        // console.log('user?.uid', user?.uid);
        // console.log('user?.displayName', user?.displayName);
        resolve({
          uid: user?.uid || '',
          username: user?.displayName || '',
        });
      });
    });
  }

  // Get all comments for a post
  getCommentsByPost(postId: string): Observable<CommentDocument> {
    const postDoc = doc(this.firestore, `comments/${postId}`);
    return docData(postDoc, { idField: 'id' }) as Observable<CommentDocument>;
  }

  // Update a specific comment in the array
  async updateComment(
    postId: string,
    commentId: string,
    updatedFields: Partial<CommentInterface>
  ): Promise<void> {
    const postDocRef = doc(this.firestore, `comments/${postId}`);
  
    try {
      // Fetch the document
      const docSnapshot = await getDoc(postDocRef);
  
      if (docSnapshot.exists()) {
        const postData = docSnapshot.data() as CommentDocument;
  
        // Find the comment and update its fields
        const updatedComments = postData.comments.map((comment) =>
          comment.id === commentId ? { ...comment, ...updatedFields } : comment
        );
  
        // Update the document with the modified comments array
        await updateDoc(postDocRef, { comments: updatedComments });
  
        // console.log('Comment updated successfully');
      } else {
        console.error('Post not found');
      }
    } catch (error) {
      console.error('Error updating comment:', error);
    }
  }

  // Delete a specific comment from the array
  async deleteComment(postId: string, commentId: string): Promise<void> {
    const postDoc = doc(this.firestore, `comments/${postId}`);
    const postData = (await firstValueFrom(docData(postDoc))) as CommentDocument;

    const updatedComments = postData.comments.filter(comment => comment.id !== commentId);
    await updateDoc(postDoc, { comments: updatedComments });
  }
}
