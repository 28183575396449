<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <textarea class="comment-form-textarea" formControlName="title"></textarea>
  <button class="comment-form-button" type="submit" [disabled]="form.invalid">
    {{ submitLabel }}
  </button>
  <button
    *ngIf="hasCancelButton"
    type="button"
    class="comment-form-button comment-form-cancel-button"
    (click)="handleCancel.emit()"
  >
    Cancel
  </button>
</form>
