<div class="comment">
  <div class="comment-image-container">
    <!-- <img src="/assets/user-icon.png" /> -->
  </div>
  <div class="comment-right-part">
    <div class="comment-content">
      <div class="comment-author">{{ comment.username }}</div>
      <div>{{ createdAt }}</div>
    </div>
    <div class="comment-text" *ngIf="!isEditing()">{{ comment.body }}</div>
    <comment-form
      *ngIf="isEditing()"
      submitLabel="Update"
      [hasCancelButton]="true"
      [initialText]="comment.body"
      (handleSubmit)="
        updateComment.emit({ text: $event, commentId: comment.id })
      "
      (handleCancel)="setActiveComment.emit(null)"
    ></comment-form>
    <div class="comment-actions">
      <div
        *ngIf="canReply"
        class="comment-action"
        (click)="
          setActiveComment.emit({
            id: comment.id,
            type: activeCommentType.replying
          })
        "
      >
        Reply
      </div>
      <div
        *ngIf="canEdit"
        class="comment-action"
        (click)="
          setActiveComment.emit({
            id: comment.id,
            type: activeCommentType.editing
          })
        "
      >
        Edit
      </div>
      <div
        *ngIf="canDelete"
        class="comment-action"
        (click)="deleteComment.emit(comment.id)"
      >
        Delete
      </div>
    </div>
    <comment-form
      *ngIf="isReplying()"
      submitLabel="Reply"
      (handleSubmit)="addComment.emit({ text: $event, parentId: replyId })"
    ></comment-form>
    <div class="replies" *ngIf="replies.length > 0">
      <comment
        *ngFor="let reply of replies"
        [comment]="reply"
        (setActiveComment)="setActiveComment.emit($event)"
        [activeComment]="activeComment"
        (updateComment)="updateComment.emit($event)"
        (deleteComment)="deleteComment.emit($event)"
        (addComment)="addComment.emit($event)"
        [parentId]="comment.id"
        [replies]="[]"
        [currentUserId]="currentUserId"
      ></comment>
    </div>
  </div>
</div>
