import { Injectable } from '@angular/core';
import { from, Observable, switchMap } from 'rxjs';
import { CommentInterface } from '../types/comment.interface';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@db/auth/auth.service';
import { UserDbService } from '@db/user/user-db.service';
import { Auth } from '@angular/fire/auth';
import { user } from 'rxfire/auth';


interface UserCredentials {
  uid: string;
  username: string;
}

@Injectable()
export class CommentsService {
  constructor(private httpClient: HttpClient,
    private us:UserDbService, private auth: Auth) {}

    uid:string | undefined ='';
    userName : undefined |string='';
    getUser(){
     
      this.us.user$.subscribe(user=>{
        this.uid = user?.uid;
        this.userName = user?.username;
      }
      );
    }
    //  async getUid(): Promise<string>{
    //  await user(this.auth).subscribe(user=>{
    //   console.log('user',user);
    //   console.log('user?.uid',user?.uid);
    //   let uid = user?.uid;
    //     return uid;
    //    // this.userName = user?.displayName;
    //   }
    //   );

    //   return '';
    // }


    // async getUid(): Promise<string> {
    //   return new Promise((resolve) => {
    //     user(this.auth).subscribe((user) => {
    //       console.log('user', user);
    //       console.log('user?.uid', user?.uid);
    //       resolve(user?.uid || ''); // Resolve with an empty string if uid is missing
    //     });
    //   });
    // }

    async getUserCredentials(): Promise<UserCredentials> {
      return new Promise((resolve) => {
        user(this.auth).subscribe((user) => {
          // console.log('user', user);
          // console.log('user?.uid', user?.uid);
          // console.log('user?.displayName', user?.displayName);
          resolve({
            uid: user?.uid || '',
            username: user?.displayName || '',
          });
        });
      });
    }
    
  getComments(): Observable<CommentInterface[]> {
    return this.httpClient.get<CommentInterface[]>(
      'http://localhost:3000/comments'
    );
  }

  createComment(
    text: string,
    parentId: string | null = null,
    uid: string | null = null,
    userName: string | null = null
  ): Observable<CommentInterface> {
    return from(this.getUserCredentials()).pipe(
      switchMap((userCredentials) => {
        const body = {
          text,
          parentId,
          createdAt: new Date().toISOString(),
          userId: userCredentials.uid,
          username: userCredentials.username, // Assuming you have a way to get userName
        };
        return this.httpClient.post<CommentInterface>('http://localhost:3000/comments', body);
      })
    );
  }

  updateComment(id: string, text: string): Observable<CommentInterface> {
    return this.httpClient.patch<CommentInterface>(
      `http://localhost:3000/comments/${id}`,
      {
        body: text,
      }
    );
  }

  deleteComment(id: string): Observable<{}> {
    return this.httpClient.delete(`http://localhost:3000/comments/${id}`);
  }
}
function fromPromise(arg0: Promise<string>) {
  throw new Error('Function not implemented.');
}

